<template>
  <v-dialog width="400" v-model="visible" :persistent="true">
    <v-card>
      <v-toolbar dark flat dense color="primary">{{ title }}</v-toolbar>
      <v-card-text v-if="action === 'edit' || action === 'create'" class="py-2">
        <v-text-field v-model="fid" :label="$t('users.id')" :error="fidError"/>
        <v-text-field v-model="name" :label="$t('users.name')" :error="nameError"/>
        <v-text-field v-model="email" :label="$t('users.email')" :error="emailError"/>
        <v-text-field v-model="premium" :label="$t('users.access')" :error="premiumError" :placeholder="today"/>
        <v-select v-model="status" :items="['user', 'mod', 'admin']" :label="$t('users.status')">
          <template slot="selection" slot-scope="data">{{ $t('status.' + data.item) }}</template>
          <template slot="item" slot-scope="data">{{ $t('status.' + data.item) }}</template>
        </v-select>
      </v-card-text>
      <v-card-text v-if="action === 'students'" class="py-2">
        <div class="d-flex flex-row flex-nowrap justify-center align-center">
          <v-checkbox v-model="checkAll" @change="changeAllStudents()"/>
          <v-text-field dense outlined hide-details v-model="search" :label="$t('common.search')"/>
        </div>
        <v-checkbox dense v-for="user in filteredUsers"
                    :key="user.uid" v-model="students" :label="user.name + ' (' + user.fid + ')'" :value="user.uid"/>
      </v-card-text>
      <v-card-text v-if="action === 'delete'" class="py-2" style="white-space: pre-line">
        {{ $t('users.if-delete-user') }}
      </v-card-text>
      <v-card-text v-if="action === 'reset'" class="py-2" style="white-space: pre-line">
        {{ $t('users.if-reset-password') }}
      </v-card-text>
      <v-card-text v-if="action === 'info'" class="py-2" style="white-space: pre-line">
        {{ message }}
      </v-card-text>
      <v-card-text v-if="action === 'image'" class="py-2" style="white-space: pre-line">
        <v-img :src="this.$url + '/uploads/profiles/' + uid"
               style="border-radius: 80px; box-shadow: 0 0 0 4px #336799; height: 160px; margin: 12px auto; width: 160px"/>
        <v-img :src="this.$url + '/uploads/backgrounds/' + uid"
               style="box-shadow: 0 0 0 4px #336799; height: 150px; margin: 12px auto; width: 243px"/>
        <div style="text-align: center; width: 100%; margin: 4px 0">
          <v-btn color="primary" @click="deleteImage('profile')" :disabled="lock">{{ $t('users.delete-profile') }}</v-btn>
        </div>
        <div style="text-align: center; width: 100%; margin: 4px 0">
          <v-btn color="primary" @click="deleteImage('background')" :disabled="lock">{{ $t('users.delete-background') }}</v-btn>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="cancel()" v-if="action !== 'info' && action !== 'image'">
          {{ $t('users.cancel') }}
        </v-btn>
        <v-btn color="primary" @click="confirm()" :disabled="disabledConfirm || lock">{{ $t('users.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UserDialog',
  data: () => ({
    visible: false, action: '', title: '', message: '', lock: false,
    uid: '', status: '',
    fid: '', fidError: false,
    name: '', nameError: false,
    email: '', emailError: false,
    premium: '', premiumError: false,
    students: [], users: [], search: '', checkAll: false
  }),
  computed: {
    disabledConfirm() {
      if (this.action === 'edit' || this.action === 'create') {
        return this.fidError || this.nameError || this.emailError || this.premiumError;
      } else return this.lock;
    },
    filteredUsers() {
      return this.users.filter((u) => u.name.includes(this.search) || u.fid.includes(this.search));
    },
    today() {
      return new Date().toISOString().split('T')[0];
    }
  },
  props: {
    onRefresh: {type: Function, required: false, default: () => ({})},
  },
  watch: {
    fid(fid) {
      if (fid.length !== fid.trim().length) this.fidError = true;
      else this.fidError = !(/^[a-zA-Z\d-]{3,16}$/.test(fid));
    },
    name(name) {
      this.nameError = name.length < 3 || name.length > 32 || name.length !== name.trim().length;
    },
    email(email) {
      if (email === '') this.emailError = false;
      else if (email.length > 254) this.emailError = true;
      else if (!(/^[-!#$%&'*+/\d=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/\d=?A-Z^_a-z`{|}~])*@[a-zA-Z\d](-*\.?[a-zA-Z\d])*\.[a-zA-Z](-?[a-zA-Z\d])+$/.test(email))) this.emailError = true;
      else {
        let parts = email.split('@');
        let domainParts = parts[1].split('.');
        if (parts[0].length > 64) this.emailError = true;
        this.emailError = domainParts.some((part) => part.length > 63);
      }
    },
    premium(premium) {
      this.premiumError = !(/^\d{4}-\d{2}-\d{2}$/.test(premium));
    },
    search() {
      this.checkAll = false;
    }
  },
  methods: {
    cancel() {
      this.visible = false;
    },
    changeAllStudents() {
      if (this.checkAll)
        this.students = [...new Set(this.students.concat(this.filteredUsers.map((u) => u.uid)))];
      else {
        let studentsToRemove = this.filteredUsers.map((u) => u.uid);
        this.students = this.students.filter((u) => !studentsToRemove.includes(u));
      }
    },
    confirm() {
      this.lock = true;
      if (this.action === 'create') {
        this.$api.createUser(this.fid, this.name, this.email, this.premium, this.status.toUpperCase()).then((user) => {
          this.message = this.$t('users.user-created');
          this.message += '\n' + this.$t('users.id') + ': ' + user.fid;
          this.message += '\n' + this.$t('users.name') + ': ' + user.name;
          this.message += '\n' + this.$t('users.email') + ': ' + user.email;
          this.message += '\n' + this.$t('users.password') + ': ' + user.password;
          this.message += '\n' + this.$t('users.access') + ': ' + user.premium;
          this.message += '\n' + this.$t('users.status') + ': ' + this.$t('status.' + user.status.toLowerCase());
          this.action = 'info';
          this.onRefresh();
        }).catch(() => {
          this.message = this.$t('common.error');
          this.action = 'info';
        }).finally(() => {
          this.lock = false;
        });
      } else if (this.action === 'edit') {
        this.$api.editUser(this.uid, this.fid, this.name, this.email, this.premium, this.status.toUpperCase()).then(() => {
          this.message = this.$t('users.user-edited');
          this.action = 'info';
          this.onRefresh();
        }).catch(() => {
          this.message = this.$t('common.error');
          this.action = 'info';
        }).finally(() => {
          this.lock = false;
        });
      } else if (this.action === 'students') {
        this.$api.editUserStudents(this.uid, this.students).then(() => {
          this.message = this.$t('users.students-edited');
          this.action = 'info';
          this.onRefresh();
        }).catch(() => {
          this.message = this.$t('common.error');
          this.action = 'info';
        }).finally(() => {
          this.lock = false;
        });
      } else if (this.action === 'delete') {
        this.$api.deleteUser(this.uid).then(() => {
          this.message = this.$t('users.user-deleted');
          this.action = 'info';
          this.onRefresh();
        }).catch(() => {
          this.message = this.$t('common.error');
          this.action = 'info';
        }).finally(() => {
          this.lock = false;
        });
      } else if (this.action === 'reset') {
        this.$api.resetUserPassword(this.uid).then((user) => {
          this.message = this.$t('users.new-password') + ': ' + user.password;
          this.action = 'info';
        }).catch(() => {
          this.message = this.$t('common.error');
          this.action = 'info';
        }).finally(() => {
          this.lock = false;
        });
      } else if (this.action === 'info') {
        this.visible = false;
      } else if (this.action === 'image') {
        this.visible = false;
      }
    },
    createUser() {
      this.action = 'create';
      this.title = this.$t('users.header-create');
      this.uid = '';
      this.fid = '';
      this.name = '';
      this.email = '';
      this.premium = new Date().toISOString().split('T')[0];
      this.status = 'user';
      this.lock = false;
      this.visible = true;
    },
    deleteUser(user) {
      this.action = 'delete';
      this.title = this.$t('users.header-delete');
      this.uid = user.uid;
      this.lock = false;
      this.visible = true;
    },
    deleteUserImage(user) {
      this.action = 'image';
      this.title = this.$t('users.header-image');
      this.uid = user.uid;
      this.lock = false;
      this.visible = true;
    },
    editUser(user) {
      this.action = 'edit';
      this.title = this.$t('users.header-edit');
      this.uid = user.uid;
      this.fid = user.fid;
      this.name = user.name;
      this.email = user.email;
      this.premium = user.premium;
      this.status = ['user', 'mod', 'admin'][user.status];
      this.lock = false;
      this.visible = true;
    },
    editUserStudents(user, users, students) {
      this.action = 'students';
      this.title = this.$t('users.header-students');
      this.uid = user.uid;
      this.users = users;
      this.students = students.filter((student) => student['mod'] === user.uid).map((student) => student['user']);
      this.search = '';
      this.checkAll = false;
      this.lock = false;
      this.visible = true;
    },
    resetUserPassword(user) {
      this.action = 'reset';
      this.title = this.$t('users.header-reset');
      this.uid = user.uid;
      this.lock = false;
      this.visible = true;
    },
    deleteImage(type) {
      this.lock = true;
      this.$api.deleteUserImage(this.uid, type).then(() => {
        this.$store.commit('showSnackbar', this.$t('users.image-deleted'));
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('common.error'));
      }).finally(() => {
        this.lock = false;
      });
    }
  }
};
</script>
