<template>
  <v-container fluid class="pa-0">

    <!-- Header -->
    <Header :title="$t('headers.users.title')" :description="$t('headers.users.description')"/>

    <!-- Content -->
    <v-card class="ma-2" flat>
      <v-card-title>
        <v-text-field single-line dense outlined hide-details v-model="search" class="my-1"
                      prepend-inner-icon="mdi-magnify" :label="$t('common.search')"/>
        <v-spacer/>
        <v-btn color="primary" @click="$refs.dialog.createUser()">{{ $t('users.create-user') }}</v-btn>
      </v-card-title>
      <v-data-table dense class="ma-2" :headers="headers" :items="users" :search="search" :loading="loading"
                    :loading-text="$t('users.loading')" hide-default-footer :items-per-page="-1">
        <template v-slot:item.premium="{item}">
          <v-icon v-if="item.premium >= today" color="green">mdi-check</v-icon>
          <v-icon v-else color="red">mdi-close</v-icon>
          {{ item.premium }}
        </template>
        <template v-slot:item.status="{item}">
          {{ $t('status.' + ['user', 'mod', 'admin'][item.status]) }}
        </template>
        <template v-slot:item.actions="{item}">
          <v-icon v-if="item.status === 1" class="ml-2"
                  @click="$refs.dialog.editUserStudents(item, users, moderation)">
            mdi-account-school-outline
          </v-icon>
          <v-icon v-if="$store.state.profile.uid !== item.uid" class="ml-2" @click="$refs.dialog.deleteUserImage(item)">
            mdi-image-outline
          </v-icon>
          <v-icon v-if="$store.state.profile.uid !== item.uid" class="ml-2"
                  @click="$refs.dialog.resetUserPassword(item)">
            mdi-lock-reset
          </v-icon>
          <v-icon v-if="$store.state.profile.uid !== item.uid" class="ml-2" @click="$refs.dialog.editUser(item)">
            mdi-pencil-outline
          </v-icon>
          <v-icon v-if="$store.state.profile.uid !== item.uid" class="ml-2" @click="$refs.dialog.deleteUser(item)">
            mdi-delete-outline
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!-- User dialog -->
    <UserDialog ref="dialog" :on-refresh="load"/>

  </v-container>
</template>

<script>
import Header from '../components/HeaderBar';
import UserDialog from '../components/UserDialog';

export default {
  name: 'MainUsers',
  components: {Header, UserDialog},
  data: () => ({
    loading: true, users: [], moderation: [], search: ''
  }),
  computed: {
    headers() {
      return [
        {text: this.$t('users.id'), value: 'fid', align: 'start', sortable: true},
        {text: this.$t('users.name'), value: 'name', align: 'start', sortable: true},
        {text: this.$t('users.email'), value: 'email', align: 'start', sortable: true},
        {text: this.$t('users.access'), value: 'premium', align: 'start', sortable: true},
        {text: this.$t('users.status'), value: 'status', align: 'start', sortable: true},
        {text: '', value: 'actions', align: 'end', sortable: false}
      ]
    },
    today() {
      return new Date().toISOString().split('T')[0];
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$api.getAllUsers().then(({users, moderation}) => {
        this.users = users;
        this.moderation = moderation;
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('common.error'));
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>
